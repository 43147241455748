import React from 'react'
import { graphql } from 'gatsby'
import Footer from '../components/footer'
import { getImage } from "gatsby-plugin-image"
import Nav from '../components/nav'
import Seo from '../components/seo'

const EntryPage = ({data}) => {
  const entry = data.markdownRemark
  const image = getImage(entry.frontmatter.image)
	return (
    <div>
      <Nav />
      <Seo title={entry.frontmatter.page_title} description={entry.frontmatter.meta_description} image="" pathname='/contact/thanks/' />
      <div className="w-full py-12 bg-blue">
        <div className="w-10/12 mx-auto max-w-screen-2xl">
          <div className="flex justify-around items-center text-center">
            <div className="w-11/12 lg:w-6/12 font-verdana">
              <h1 className="font-shoulders uppercase font-bold text-white text-6xl lg:text-8xl leading-none">{entry.frontmatter.heading}</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full pt-12 pb-12 border-b-2 border-grey-c">
        <div className="w-11/12 mx-auto max-w-screen-2xl">
          <div className="flex-none sm:flex justify-around gap-24">
            <div className="w-full sm:w-8/12">
              <div className="max-w-none font-verdana prose prose-lg text-center">
                <div dangerouslySetInnerHTML={{ __html: entry.html }} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
	)
}

export default EntryPage;

export const query = graphql`
	query($slug: String!) {
		markdownRemark( fields: { slug: { eq: $slug } }) {
			frontmatter {
        title
				page_title
				meta_description
				heading
        image {
					childImageSharp {
            gatsbyImageData(width: 1280)
          }
				}
			}
			fields {
				slug
			}
      html
		}
	}
`